import { any } from "prop-types";
import { CLINICS_REQUEST, PERMISSIONS_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {


  dhisClinicsState:{
    
  },

  getClinicListLoading: false,
  getClinicListSuccess: false,
  getClinicListFailure: false,
  getClinicListError: null,
  clinicsList: [],
  clinicsListCount: 0,

  getTagsListLoading: false,
  getTagsListSuccess: false,
  getTagsListFailure: false,
  getTagsListError: null,
  getTagsList: [],

  addNewClinicLoading: false,
  addNewClinicSuccess: false,
  addNewClinicFailure: false,
  addNewClinicError: null,

  getSingleClinicDetailsLoading: false,
  getSingleClinicDetailsSuccess: false,
  getSingleClinicDetailsFailure: false,
  getSingleClinicDetailsError: null,
  singleClinicDetails: false,

  getClinicListSelectLoading: false,
  getClinicListSelectSuccess: false,
  getClinicListSelectFailure: false,
  getClinicListSelectError: null,
  clinicsListSelect: [],

  adminTallyValidationLoading: false,
  adminTallyValidationSuccess: false,
  adminTallyValidationFailure: false,
  adminTallyValidationError: null,
  adminTallyValidation: [],
  

  getAllMappingClinicListSelectLoading: false,
  getAllMappingClinicListSelectSuccess: false,
  getAllMappingClinicListSelectFailure: false,
  getAllMappingClinicListSelectError: null,
  getAllMappingSelectData: [],

  getAllRolesLoading: false,
  getAllRolesSuccess: false,
  getAllRolesFailure: false,
  getAllRolesError: null,
  getAllRolesData: [],

  getAllPolicyGroupsLoading: false,
  getAllPolicyGroupsSuccess: false,
  getAllPolicyGroupsFailure: false,
  getAllPolicyGroupsError: null,
  getAllPolicyGroupsData: [],

  getAllPolicesLoading: false,
  getAllPolicesSuccess: false,
  getAllPolicesFailure: false,
  getAllPolicesError: null,
  getAllPolicesData: [],

  addRoleLoading: false,
  addRoleSuccess: false,
  addRoleFailure: false,
  addRoleError: null,
  addRoleData: [],

  assignPoliciesLoading: false,
  assignPoliciesSuccess: false,
  assignPoliciesFailure: false,
  assignPoliciesError: null,
  assignPoliciesData: [],

  getPolicesByRoleIdLoading: false,
  getPolicesByRoleIdSuccess: false,
  getPolicesByRoleIdFailure: false,
  getPolicesByRoleIdError: null,
  getPolicesByRoleIdData: [],

  getSidebarPolicesByRoleIdLoading: false,
  getSidebarPolicesByRoleIdSuccess: false,
  getSidebarPolicesByRoleIdFailure: false,
  getSidebarPolicesByRoleIdError: null,
  getSidebarPolicesByRoleIdData: [],

  getDhis2CredentialsLoading: false,
  getDhis2CredentialsSuccess: false,
  getDhis2CredentialsFailure: false,
  getDhis2CredentialsError: null,
  getDhis2CredentialsData: [],

  updateDhis2CredentialsLoading: false,
  updateDhis2CredentialsSuccess: false,
  updateDhis2CredentialsFailure: false,
  updateDhis2CredentialsError: null,
  updateDhis2CredentialsData: [],

  getAdministeredLocationTypesLoading: false,
  getAdministeredLocationTypesSuccess: false,
  getAdministeredLocationTypesFailure: false,
  getAdministeredLocationTypesError: null,
  getAdministeredLocationTypesData: [],

  addPolicyGroupLoading: false,
  addPolicyGroupSuccess: false,
  addPolicyGroupFailure: false,
  addPolicyGroupError: null,
  addPolicyGroupData: [],

  deleteRoleLoading: false,
  deleteRoleSuccess: false,
  deleteRoleFailure: false,
  deleteRoleError: null,
  deleteRoleData: [],
};

export const clinicsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case CLINICS_REQUEST.GET_DHIS_CLINICS:
      return {
        ...state,
        dhisClinicsState: action
      }

    case PERMISSIONS_REQUEST.ADD_POLICY_GROUP_REQUEST:
      return {
        ...state,
        addPolicyGroupLoading: true,
        addPolicyGroupSuccess: false,
        addPolicyGroupFailure: false,
        addPolicyGroupError: null,
        addPolicyGroupData: null
      };
    case PERMISSIONS_REQUEST.ADD_POLICY_GROUP_FAILURE:
      return {
        ...state,
        addPolicyGroupLoading: false,
        addPolicyGroupSuccess: false,
        addPolicyGroupFailure: true,
        addPolicyGroupError: action.payload,
      };
    case PERMISSIONS_REQUEST.ADD_POLICY_GROUP_SUCCESS:
      return {
        ...state,
        addPolicyGroupLoading: false,
        addPolicyGroupSuccess: true,
        addPolicyGroupFailure: false,
        addPolicyGroupError: null,
        addPolicyGroupData: action.payload,
      };
    case PERMISSIONS_REQUEST.DELETE_ROLE_REQUEST:
      return {
        ...state,
        deleteRoleLoading: true,
        deleteRoleSuccess: false,
        deleteRoleFailure: false,
        deleteRoleError: null,
        deleteRoleData: null
      };
    case PERMISSIONS_REQUEST.DELETE_ROLE_FAILURE:
      return {
        ...state,
        deleteRoleLoading: false,
        deleteRoleSuccess: false,
        deleteRoleFailure: true,
        deleteRoleError: action.payload,
      };
    case PERMISSIONS_REQUEST.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRoleLoading: false,
        deleteRoleSuccess: true,
        deleteRoleFailure: false,
        deleteRoleError: null,
        deleteRoleData: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_LOCATION_TYPE_FILTER_REQUEST:
      return {
        ...state,
        getAdministeredLocationTypesLoading: true,
        getAdministeredLocationTypesSuccess: false,
        getAdministeredLocationTypesFailure: false,
        getAdministeredLocationTypesError: null,
        getAdministeredLocationTypesData: null
      };
    case PERMISSIONS_REQUEST.GET_LOCATION_TYPE_FILTER_FAILURE:
      return {
        ...state,
        getAdministeredLocationTypesLoading: false,
        getAdministeredLocationTypesSuccess: false,
        getAdministeredLocationTypesFailure: true,
        getAdministeredLocationTypesError: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_LOCATION_TYPE_FILTER_SUCCESS:
      return {
        ...state,
        getAdministeredLocationTypesLoading: false,
        getAdministeredLocationTypesSuccess: true,
        getAdministeredLocationTypesFailure: false,
        getAdministeredLocationTypesError: null,
        getAdministeredLocationTypesData: action.payload,
      };
    case PERMISSIONS_REQUEST.UPDATE_DHIS2_CREDENTIAS_REQUEST:
      return {
        ...state,
        updateDhis2CredentialsLoading: true,
        updateDhis2CredentialsSuccess: false,
        updateDhis2CredentialsFailure: false,
        updateDhis2CredentialsError: null,
        updateDhis2CredentialsData: null
      };
    case PERMISSIONS_REQUEST.UPDATE_DHIS2_CREDENTIAS_FAILURE:
      return {
        ...state,
        updateDhis2CredentialsLoading: false,
        updateDhis2CredentialsSuccess: false,
        updateDhis2CredentialsFailure: true,
        updateDhis2CredentialsError: action.payload,
      };
    case PERMISSIONS_REQUEST.UPDATE_DHIS2_CREDENTIAS_SUCCESS:
      return {
        ...state,
        updateDhis2CredentialsLoading: false,
        updateDhis2CredentialsSuccess: true,
        updateDhis2CredentialsFailure: false,
        updateDhis2CredentialsError: null,
        updateDhis2CredentialsData: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_DHIS2_CREDENTIAS_REQUEST:
      return {
        ...state,
        getDhis2CredentialsLoading: true,
        getDhis2CredentialsSuccess: false,
        getDhis2CredentialsFailure: false,
        getDhis2CredentialsError: null,
        getDhis2CredentialsData: null
      };
    case PERMISSIONS_REQUEST.GET_DHIS2_CREDENTIAS_FAILURE:
      return {
        ...state,
        getDhis2CredentialsLoading: false,
        getDhis2CredentialsSuccess: false,
        getDhis2CredentialsFailure: true,
        getDhis2CredentialsError: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_DHIS2_CREDENTIAS_SUCCESS:
      return {
        ...state,
        getDhis2CredentialsLoading: false,
        getDhis2CredentialsSuccess: true,
        getDhis2CredentialsFailure: false,
        getDhis2CredentialsError: null,
        getDhis2CredentialsData: action.payload,
      };
    case PERMISSIONS_REQUEST.ASSIGN_POLICES_REQUEST:
      return {
        ...state,
        assignPoliciesLoading: true,
        assignPoliciesSuccess: false,
        assignPoliciesFailure: false,
        assignPoliciesError: null,
        assignPoliciesData: null
      };
    case PERMISSIONS_REQUEST.ASSIGN_POLICES_FAILURE:
      return {
        ...state,
        assignPoliciesLoading: false,
        assignPoliciesSuccess: false,
        assignPoliciesFailure: true,
        assignPoliciesError: action.payload,
      };
    case PERMISSIONS_REQUEST.ASSIGN_POLICES_SUCCESS:
      return {
        ...state,
        assignPoliciesLoading: false,
        assignPoliciesSuccess: true,
        assignPoliciesFailure: false,
        assignPoliciesError: null,
        assignPoliciesData: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_POLICES_BY_ROLE_ID_REQUEST:
      return {
        ...state,
        getPolicesByRoleIdLoading: true,
        getPolicesByRoleIdSuccess: false,
        getPolicesByRoleIdFailure: false,
        getPolicesByRoleIdError: null,
        getPolicesByRoleIdData: null
      };
    case PERMISSIONS_REQUEST.GET_ALL_POLICES_BY_ROLE_ID_FAILURE:
      return {
        ...state,
        getPolicesByRoleIdLoading: false,
        getPolicesByRoleIdSuccess: false,
        getPolicesByRoleIdFailure: true,
        getPolicesByRoleIdError: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_POLICES_BY_ROLE_ID_SUCCESS:
      return {
        ...state,
        getPolicesByRoleIdLoading: false,
        getPolicesByRoleIdSuccess: true,
        getPolicesByRoleIdFailure: false,
        getPolicesByRoleIdError: null,
        getPolicesByRoleIdData: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_REQUEST:
      return {
        ...state,
        getSidebarPolicesByRoleIdLoading: true,
        getSidebarPolicesByRoleIdSuccess: false,
        getSidebarPolicesByRoleIdFailure: false,
        getSidebarPolicesByRoleIdError: null,
        getSidebarPolicesByRoleIdData: null
      };
    case PERMISSIONS_REQUEST.GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_FAILURE:
      return {
        ...state,
        getSidebarPolicesByRoleIdLoading: false,
        getSidebarPolicesByRoleIdSuccess: false,
        getSidebarPolicesByRoleIdFailure: true,
        getSidebarPolicesByRoleIdError: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_SUCCESS:
      return {
        ...state,
        getSidebarPolicesByRoleIdLoading: false,
        getSidebarPolicesByRoleIdSuccess: true,
        getSidebarPolicesByRoleIdFailure: false,
        getSidebarPolicesByRoleIdError: null,
        getSidebarPolicesByRoleIdData: action.payload,
      };
    case PERMISSIONS_REQUEST.ADD_ROLE_REQUEST:
      return {
        ...state,
        addRoleLoading: true,
        addRoleSuccess: false,
        addRoleFailure: false,
        addRoleError: null,
        addRoleData: null
      };
    case PERMISSIONS_REQUEST.ADD_ROLE_FAILURE:
      return {
        ...state,
        addRoleLoading: false,
        addRoleSuccess: false,
        addRoleFailure: true,
        addRoleError: action.payload,
      };
    case PERMISSIONS_REQUEST.ADD_ROLE_SUCCESS:
      return {
        ...state,
        addRoleLoading: false,
        addRoleSuccess: true,
        addRoleFailure: false,
        addRoleError: null,
        addRoleData: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_ROLES_REQUEST:
      return {
        ...state,
        getAllRolesLoading: true,
        getAllRolesSuccess: false,
        getAllRolesFailure: false,
        getAllRolesError: null,
        getAllRolesData: null
      };
    case PERMISSIONS_REQUEST.GET_ALL_ROLES_FAILURE:
      return {
        ...state,
        getAllRolesLoading: false,
        getAllRolesSuccess: false,
        getAllRolesFailure: true,
        getAllRolesError: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        getAllRolesLoading: false,
        getAllRolesSuccess: true,
        getAllRolesFailure: false,
        getAllRolesError: null,
        getAllRolesData: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_POLICY_GROUPS_REQUEST:
      return {
        ...state,
        getAllPolicyGroupsLoading: true,
        getAllPolicyGroupsSuccess: false,
        getAllPolicyGroupsFailure: false,
        getAllPolicyGroupsError: null,
        getAllPolicyGroupsData: null
      };
    case PERMISSIONS_REQUEST.GET_ALL_POLICY_GROUPS_FAILURE:
      return {
        ...state,
        getAllPolicyGroupsLoading: false,
        getAllPolicyGroupsSuccess: false,
        getAllPolicyGroupsFailure: true,
        getAllPolicyGroupsError: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_POLICY_GROUPS_SUCCESS:
      return {
        ...state,
        getAllPolicyGroupsLoading: false,
        getAllPolicyGroupsSuccess: true,
        getAllPolicyGroupsFailure: false,
        getAllPolicyGroupsError: null,
        getAllPolicyGroupsData: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_POLICES_REQUEST:
      return {
        ...state,
        getAllPolicesLoading: true,
        getAllPolicesSuccess: false,
        getAllPolicesFailure: false,
        getAllPolicesError: null,
        getAllPolicesData: null
      };
    case PERMISSIONS_REQUEST.GET_ALL_POLICES_FAILURE:
      return {
        ...state,
        getAllPolicesLoading: false,
        getAllPolicesSuccess: false,
        getAllPolicesFailure: true,
        getAllPolicesError: action.payload,
      };
    case PERMISSIONS_REQUEST.GET_ALL_POLICES_SUCCESS:
      return {
        ...state,
        getAllPolicesLoading: false,
        getAllPolicesSuccess: true,
        getAllPolicesFailure: false,
        getAllPolicesError: null,
        getAllPolicesData: action.payload,
      };


    case CLINICS_REQUEST.GET_CLINICS_LIST_REQUEST:
      return {
        ...state,
        getClinicListLoading: true,
        getClinicListSuccess: false,
        getClinicListFailure: false,
        getClinicListError: null,
        clinicsList: [],
        clinicsListCount: 0,
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_FAILURE:
      return {
        ...state,
        getClinicListLoading: false,
        getClinicListSuccess: false,
        getClinicListFailure: true,
        getClinicListError: action.payload,
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_SUCCESS:
      return {
        ...state,
        getClinicListLoading: false,
        getClinicListSuccess: true,
        getClinicListFailure: false,
        getClinicListError: null,
        clinicsList: action.payload,
        clinicsListCount: action.info.count,
      };

    case CLINICS_REQUEST.GET_TAGS_LIST_REQUEST:
      return {
        ...state,
        getTagsListLoading: true,
        getTagsListSuccess: false,
        getTagsListFailure: false,
        getTagsListError: null,
        getTagsList: [],
      };
    case CLINICS_REQUEST.GET_TAGS_LIST_FAILURE:
      return {
        ...state,
        getTagsListLoading: false,
        getTagsListSuccess: false,
        getTagsListFailure: true,
        getTagsListError: action.payload,
      };
    case CLINICS_REQUEST.GET_TAGS_LIST_SUCCESS:
      return {
        ...state,
        getTagsListLoading: false,
        getTagsListSuccess: true,
        getTagsListFailure: false,
        getTagsListError: null,
        getTagsList: action.payload,
      };
    case CLINICS_REQUEST.ADD_NEW_CLINIC_REQUEST:
      return {
        ...state,
        addNewClinicLoading: true,
        addNewClinicSuccess: false,
        addNewClinicFailure: false,
        addNewClinicError: null,
      };
    case CLINICS_REQUEST.ADD_NEW_CLINIC_FAILURE:
      return {
        ...state,
        addNewClinicLoading: false,
        addNewClinicSuccess: false,
        addNewClinicFailure: true,
        addNewClinicError: action.payload,
      };
    case CLINICS_REQUEST.ADD_NEW_CLINIC_SUCCESS:
      return {
        ...state,
        addNewClinicLoading: false,
        addNewClinicSuccess: true,
        addNewClinicFailure: false,
        addNewClinicError: null,
      };
    case CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_REQUEST:
      return {
        ...state,
        getSingleClinicDetailsLoading: true,
        getSingleClinicDetailsSuccess: false,
        getSingleClinicDetailsFailure: false,
        getSingleClinicDetailsError: null,
        singleClinicDetails: false,
      };
    case CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_FAILURE:
      return {
        ...state,
        getSingleClinicDetailsLoading: false,
        getSingleClinicDetailsSuccess: false,
        getSingleClinicDetailsFailure: true,
        getSingleClinicDetailsError: action.payload,
      };
    case CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_SUCCESS:
      return {
        ...state,
        getSingleClinicDetailsLoading: false,
        getSingleClinicDetailsSuccess: true,
        getSingleClinicDetailsFailure: false,
        getSingleClinicDetailsError: null,
        singleClinicDetails: action.payload,
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_REQUEST:
      return {
        ...state,
        getClinicListSelectLoading: true,
        getClinicListSelectSuccess: false,
        getClinicListSelectFailure: false,
        getClinicListSelectError: null,
        clinicsList: [],
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_FAILURE:
      return {
        ...state,
        getClinicListSelectLoading: false,
        getClinicListSelectSuccess: false,
        getClinicListSelectFailure: true,
        getClinicListSelectError: action.payload,
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_SUCCESS:
      return {
        ...state,
        getClinicListSelectLoading: false,
        getClinicListSelectSuccess: true,
        getClinicListSelectFailure: false,
        getClinicListSelectError: null,
        clinicsListSelect: action.payload,
      };
    case CLINICS_REQUEST.ADMIN_TALLY_VALIDATION_REQUEST:
      return {
        ...state,
        adminTallyValidationLoading: true,
        adminTallyValidationSuccess: false,
        adminTallyValidationFailure: false,
        adminTallyValidationError: null,
        adminTallyValidation: [],
      };
    case CLINICS_REQUEST.ADMIN_TALLY_VALIDATION_FAILURE:
      return {
        ...state,
        adminTallyValidationLoading: false,
        adminTallyValidationSuccess: false,
        adminTallyValidationFailure: true,
        adminTallyValidationError: action.payload,
      };
    case CLINICS_REQUEST.ADMIN_TALLY_VALIDATION_SUCCESS:
      return {
        ...state,
        adminTallyValidationLoading: false,
        adminTallyValidationSuccess: true,
        adminTallyValidationFailure: false,
        adminTallyValidationError: null,
        adminTallyValidation: action.payload,
      };
    case CLINICS_REQUEST.GET_CLINICS_MAPPING_LIST_SELECT_REQUEST:
      return {
        ...state,
        getAllMappingClinicListSelectLoading: true,
        getAllMappingClinicListSelectSuccess: false,
        getAllMappingClinicListSelectFailure: false,
        getAllMappingClinicListSelectError: null,
        getAllMappingSelectData: [],
      };
    case CLINICS_REQUEST.GET_CLINICS_MAPPING_LIST_SELECT_FAILURE:
      return {
        ...state,
        getAllMappingClinicListSelectLoading: false,
        getAllMappingClinicListSelectSuccess: false,
        getAllMappingClinicListSelectFailure: true,
        getAllMappingClinicListSelectError: action.payload,
      };
    case CLINICS_REQUEST.GET_CLINICS_MAPPING_LIST_SELECT_SUCCESS:
      return {
        ...state,
        getAllMappingClinicListSelectLoading: false,
        getAllMappingClinicListSelectSuccess: true,
        getAllMappingClinicListSelectFailure: false,
        getAllMappingClinicListSelectError: null,
        getAllMappingSelectData: action.payload,
      };

    default:
      return state;
  }
};
