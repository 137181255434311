import { LOCATIONS } from "../actions/utilities";

const INITIAL_STATE = {
  getLocationLoading: false,
  getLocationSuccess: false,
  getLocationFailure: false,
  getLocationError: null,
  locationsList: [],

  getAllLocationLoading: false,
  getAllLocationSuccess: false,
  getAllLocationFailure: false,
  getAllLocationError: null,
  allLocationsList: [],

  getAllDoctorDesignationsLoading: false,
  getAllDoctorDesignationsSuccess: false,
  getAllDoctorDesignationsFailure: false,
  getAllDoctorDesignationsError: null,
  allDoctorDesignationssList: [],

  addLocationLoading: false,
  addLocationSuccess: false,
  addLocationFailure: false,
  addLocationError: null,
  addlocationsList: null,


  stateValue: null,
  districtValue: null,
  regionValue: null,
  clinicValue: null,
  pageValue:1,

  filterData:null


};

export const locationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'STATE':
      return {
        ...state,
        stateValue: action.payload
      }
    case 'REGION':
      return {
        ...state,
        regionValue: action.payload
      }
    case 'DISTRICT':
      return {
        ...state,
        districtValue: action.payload
      }
    case 'CLINIC':
      return {
        ...state,
        clinicValue: action.payload
      }
      case 'PAGE_NUMBER':
      return {
        ...state,
        pageValue: action.payload
      }
      case 'FILTER_DATA':
      return {
        ...state,
        filterData: action.payload
      }
    case 'RESET_LOCATIONS':
      return {
        ...state,
        stateValue: null,
        districtValue: null,
        regionValue: null,
        clinicValue: null,
        pageValue: 1,
      }
    case LOCATIONS.GET_LOCATIONS_REQUEST:
      return {
        ...state,
        getLocationLoading: true,
        getLocationSuccess: false,
        getLocationFailure: false,
        getLocationError: null,
        locationsList: [],
      };
    case LOCATIONS.GET_LOCATIONS_FAILURE:
      return {
        ...state,
        getLocationLoading: false,
        getLocationSuccess: false,
        getLocationFailure: true,
        getLocationError: action.payload,
      };
    case LOCATIONS.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        getLocationLoading: false,
        getLocationSuccess: true,
        getLocationFailure: false,
        getLocationError: null,
        locationsList: action.payload,
      };
    case LOCATIONS.GET_ALL_DOCTOR_DESIGNATIONS_REQUEST:
      return {
        ...state,
        getAllDoctorDesignationsLoading: true,
        getAllDoctorDesignationsSuccess: false,
        getAllDoctorDesignationsFailure: false,
        getAllDoctorDesignationsError: null,
        allDoctorDesignationssList: [],
      };
    case LOCATIONS.GET_ALL_DOCTOR_DESIGNATIONS_FAILURE:
      return {
        ...state,
        getAllDoctorDesignationsLoading: false,
        getAllDoctorDesignationsSuccess: false,
        getAllDoctorDesignationsFailure: true,
        getAllDoctorDesignationsError: action.payload,
      };
    case LOCATIONS.GET_ALL_DOCTOR_DESIGNATIONS_SUCCESS:
      return {
        ...state,
        getAllDoctorDesignationsLoading: false,
        getAllDoctorDesignationsSuccess: true,
        getAllDoctorDesignationsFailure: false,
        getAllDoctorDesignationsError: null,
        allDoctorDesignationssList: action.payload,
      };

    case LOCATIONS.GET_ALL_LOCATIONS_REQUEST:
      return {
        ...state,
        getAllLocationLoading: true,
        getAllLocationSuccess: false,
        getAllLocationFailure: false,
        getAllLocationError: null,
        allLocationsList: [],
      };
    case LOCATIONS.GET_ALL_LOCATIONS_FAILURE:
      return {
        ...state,
        getAllLocationLoading: false,
        getAllLocationSuccess: false,
        getAllLocationFailure: true,
        getAllLocationError: action.payload,
      };
    case LOCATIONS.GET_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        getAllLocationLoading: false,
        getAllLocationSuccess: true,
        getAllLocationFailure: false,
        getAllLocationError: null,
        allLocationsList: action.payload,
      };

    case LOCATIONS.ADD_LOCATIONS_REQUEST:
      return {
        ...state,
        addLocationLoading: true,
        addLocationSuccess: false,
        addLocationFailure: false,
        addLocationError: null,
      };
    case LOCATIONS.ADD_LOCATIONS_FAILURE:
      return {
        ...state,
        addLocationLoading: false,
        addLocationSuccess: false,
        addLocationFailure: true,
        addLocationError: action.payload,
      };
    case LOCATIONS.ADD_LOCATIONS_SUCCESS:
      return {
        ...state,
        addLocationLoading: false,
        addLocationSuccess: true,
        addLocationFailure: false,
        addLocationError: null,
        addlocationsList: action.payload,
      };

    default:
      return state;
  }
};

