import { request, success, failure, CLINICS_REQUEST, PERMISSIONS_REQUEST } from "./utilities";
import { API_PATH } from "../config";

import {
  hitApi,
  addNewClinicApi,
  getAllClinicsListApi,
  getAllMappingClinicsListApi,
  getClinicsForSelectApi,
  getSingleClinicDetailsApi,
  getAllRolesApi,
  getAllPoliciesApi,
  addOrUpdateRoleApi,
  getPolicesByRoleIdApi,
  assignPoliciesApi,
  updateDHIS2CredsApi,
  getDHIS2CredsApi,
  getAdministeredLocationTypesApi,
  addPolicyGroupApi,
  deleteRoledApi,
  adminTallyValidationApi,
  getAllPolicyGroupsApi,
} from "../api";
import { fil } from "date-fns/locale";
import { filter } from "lodash";


export function makeApiCall(filters, path, type) {
  return (dispatch) => {
    dispatch(request(type));
    hitApi(filters, path).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              type,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              type,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(type, error.message)
        );
      }
    );
  };
}

export function getDhisClinicsList(filters) {
  return makeApiCall(null, API_PATH.CLINICS.getAllCLinicsList + "?PageNumber=1&PageSize=1000&IsForDHISConfig=" + filters, CLINICS_REQUEST.GET_DHIS_CLINICS)
}

export function getAllClinicsList(filters) {
  return (dispatch) => {
    dispatch(request(CLINICS_REQUEST.GET_CLINICS_LIST_REQUEST));
    getAllClinicsListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CLINICS_REQUEST.GET_CLINICS_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              CLINICS_REQUEST.GET_CLINICS_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(CLINICS_REQUEST.GET_CLINICS_LIST_FAILURE, error.message)
        );
      }
    );
  };
}

export function addNewClinic(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CLINICS_REQUEST.ADD_NEW_CLINIC_REQUEST));
    addNewClinicApi(data)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(
              success(
                CLINICS_REQUEST.ADD_NEW_CLINIC_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CLINICS_REQUEST.ADD_NEW_CLINIC_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(CLINICS_REQUEST.ADD_NEW_CLINIC_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getSingleClinicDetails(clinicId) {
  return (dispatch) => {
    dispatch(request(CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_REQUEST));
    getSingleClinicDetailsApi(clinicId).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getAllClinicsListForSelectAction(props = false) {
  return (dispatch) => {
    dispatch(request(CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_REQUEST));
    getClinicsForSelectApi(props).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function adminTallyValidationAction(req,onSuccess) {
  return (dispatch) => {
    dispatch(request(CLINICS_REQUEST.ADMIN_TALLY_VALIDATION_REQUEST));
    adminTallyValidationApi(req).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CLINICS_REQUEST.ADMIN_TALLY_VALIDATION_SUCCESS,
              response.data.data
            )
          );
          if(onSuccess){
            onSuccess()
          }
        } else {
          dispatch(
            failure(
              CLINICS_REQUEST.ADMIN_TALLY_VALIDATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CLINICS_REQUEST.ADMIN_TALLY_VALIDATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getAllMappingClinicsListAction() {
  return (dispatch) => {
    dispatch(request(CLINICS_REQUEST.GET_CLINICS_MAPPING_LIST_SELECT_REQUEST));
    getAllMappingClinicsListApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CLINICS_REQUEST.GET_CLINICS_MAPPING_LIST_SELECT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              CLINICS_REQUEST.GET_CLINICS_MAPPING_LIST_SELECT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CLINICS_REQUEST.GET_CLINICS_MAPPING_LIST_SELECT_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getAllPolicyGroupsAction() {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.GET_ALL_POLICY_GROUPS_REQUEST));
    getAllPolicyGroupsApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.GET_ALL_POLICY_GROUPS_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.GET_ALL_POLICY_GROUPS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.GET_ALL_POLICY_GROUPS_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getAllRolesAction() {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.GET_ALL_ROLES_REQUEST));
    getAllRolesApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.GET_ALL_ROLES_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.GET_ALL_ROLES_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.GET_ALL_ROLES_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getAllPoliciesAction() {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.GET_ALL_POLICES_REQUEST));
    getAllPoliciesApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.GET_ALL_POLICES_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.GET_ALL_POLICES_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.GET_ALL_POLICES_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function addOrUpdateRoleAction(data, onSuccess) {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.ADD_ROLE_REQUEST));
    addOrUpdateRoleApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.ADD_ROLE_SUCCESS,
              response.data.data
            )
          );
          if(onSuccess){
            onSuccess(response.data.data)
          }
        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.ADD_ROLE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.ADD_ROLE_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function deleteRoleAction(data, onSuccess) {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.DELETE_ROLE_REQUEST));
    deleteRoledApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.DELETE_ROLE_SUCCESS,
              response.data.data
            )
          );
          if (onSuccess) {
            onSuccess()
          }
        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.DELETE_ROLE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.DELETE_ROLE_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getPolicesByRoleIdAction(data) {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.GET_ALL_POLICES_BY_ROLE_ID_REQUEST));
    getPolicesByRoleIdApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.GET_ALL_POLICES_BY_ROLE_ID_SUCCESS,
              response.data.data
            )
          );

        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.GET_ALL_POLICES_BY_ROLE_ID_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.GET_ALL_POLICES_BY_ROLE_ID_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getPolicesByRoleIdSidebarAction(data) {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_REQUEST));
    getPolicesByRoleIdApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_SUCCESS,
              response.data.data
            )
          );

        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.GET_ALL_SIDEBAR_POLICES_BY_ROLE_ID_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function assignPoliciesAction(data, onSuccessPolicy) {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.ASSIGN_POLICES_REQUEST));
    assignPoliciesApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.ASSIGN_POLICES_SUCCESS,
              response.data.data
            )
          );
          if (onSuccessPolicy) {

            onSuccessPolicy()
          }
        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.ASSIGN_POLICES_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.ASSIGN_POLICES_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function updateDHIS2CredsAction(data, onSuccess) {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.UPDATE_DHIS2_CREDENTIAS_REQUEST));
    updateDHIS2CredsApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.UPDATE_DHIS2_CREDENTIAS_SUCCESS,
              response.data.data
            )
          );
          if (onSuccess) {
            onSuccess()
          }
        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.UPDATE_DHIS2_CREDENTIAS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.UPDATE_DHIS2_CREDENTIAS_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getDHIS2CredsAction() {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.GET_DHIS2_CREDENTIAS_REQUEST));
    getDHIS2CredsApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.GET_DHIS2_CREDENTIAS_SUCCESS,
              response.data.data
            )
          );

        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.GET_DHIS2_CREDENTIAS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.GET_DHIS2_CREDENTIAS_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getAdministeredLocationTypesAction() {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.GET_LOCATION_TYPE_FILTER_REQUEST));
    getAdministeredLocationTypesApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.GET_LOCATION_TYPE_FILTER_SUCCESS,
              response.data.data
            )
          );

        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.GET_LOCATION_TYPE_FILTER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.GET_LOCATION_TYPE_FILTER_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function addPolicyGroupAction(data, onSuccessPolicy) {
  return (dispatch) => {
    dispatch(request(PERMISSIONS_REQUEST.ADD_POLICY_GROUP_REQUEST));
    addPolicyGroupApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PERMISSIONS_REQUEST.ADD_POLICY_GROUP_SUCCESS,
              response.data.data
            )
          );
          if (onSuccessPolicy) {

            onSuccessPolicy()
          }
        } else {
          dispatch(
            failure(
              PERMISSIONS_REQUEST.ADD_POLICY_GROUP_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PERMISSIONS_REQUEST.ADD_POLICY_GROUP_FAILURE,
            error.message
          )
        );
      }
    );
  };
}