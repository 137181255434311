import { GENERIC } from "../actions/utilities";

const INITIAL_STATE = {
  activateUserLoading: false,
  activateUserSuccess: false,
  activateUserFailure: false,
  activateUserError: null,
  activateUser: {},

  getUserSelectListExceptPatientLoading: false,
  getUserSelectListExceptPatientSuccess: false,
  getUserSelectListExceptPatientFailure: false,
  getUserSelectListExceptPatientError: null,
  getUserSelectListExceptPatient: [],

  sessionExpire: false,
};

export const genericReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GENERIC.ACTIVATE_USER_REQUEST:
      return {
        ...state,
        activateUserLoading: true,
        activateUserSuccess: false,
        activateUserFailure: false,
        activateUserError: null,
        activateUser: {},
      };
    // case GENERIC.SET_SESSION_EXPIRE_STATE:
    //   return {
    //     ...state,
    //     sessionExpire: action.payload,
    //   };
    case GENERIC.ACTIVATE_USER_FAILURE:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: false,
        activateUserFailure: true,
        activateUserError: action.payload,
      };
    case GENERIC.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: true,
        activateUserFailure: false,
        activateUserError: null,
        activateUser: action.payload,
      };
    case GENERIC.DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        activateUserLoading: true,
        activateUserSuccess: false,
        activateUserFailure: false,
        activateUserError: null,
        activateUser: {},
      };
    case GENERIC.DEACTIVATE_USER_FAILURE:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: false,
        activateUserFailure: true,
        activateUserError: action.payload,
      };
    case GENERIC.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: true,
        activateUserFailure: false,
        activateUserError: null,
        activateUser: action.payload,
      };
    case GENERIC.GET_USERS_LIST_EXCEPT_PATIENT_REQUEST:
      return {
        ...state,
        getUserSelectListExceptPatientLoading: true,
        getUserSelectListExceptPatientSuccess: false,
        getUserSelectListExceptPatientFailure: false,
        getUserSelectListExceptPatientError: null,
        getUserSelectListExceptPatient: [],
      };
    case GENERIC.GET_USERS_LIST_EXCEPT_PATIENT_FAILURE:
      return {
        ...state,
        getUserSelectListExceptPatientLoading: false,
        getUserSelectListExceptPatientSuccess: false,
        getUserSelectListExceptPatientFailure: true,
        getUserSelectListExceptPatientError: action.payload,
      };
    case GENERIC.GET_USERS_LIST_EXCEPT_PATIENT_SUCCESS:
      return {
        ...state,
        getUserSelectListExceptPatientLoading: false,
        getUserSelectListExceptPatientSuccess: true,
        getUserSelectListExceptPatientFailure: false,
        getUserSelectListExceptPatientError: null,
        getUserSelectListExceptPatient: action.payload,
      };
    default:
      return state;
  }
};
